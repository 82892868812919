
import Vue from 'vue'

export default Vue.extend({
  name: 'AdminIndex',

  data: () => {
    return {
    }
  },

  computed: {
    baseUrl () {
      return this.$store.state.baseUrl
    },
  },

  methods: {
  },
})
